import './src/styles/global.css'
import "@fortawesome/fontawesome-svg-core/styles.css"

import React from 'react';
import BownowContext from './src/contexts/BownowContext';

// !!set bownow id here!! it's passed to contact component by Context API
const BowNowId = {
    ts: 'UTC_2e40f188133d5e94db53',
    cs: 'sid_1be068f99ec7bfa59b45',
}

export const wrapRootElement = ({ element }) => {
  return (
    <BownowContext.Provider value={{ BownowId: BowNowId }}>
      {element}
    </BownowContext.Provider>
  );
};

//bownow form script
const onRouteUpdate = () => {
    const scriptTs = `https://contents.bownow.jp/js/${BowNowId.ts}/trace.js`;
    const scriptCs = `https://contents.bownow.jp/forms/${BowNowId.cs}/trace.js`;

    const scripts = document.getElementsByTagName('script');
    for (let i = scripts.length - 1; i >= 0; i--) {
        const script = scripts[i];
        if (script.src.includes(scriptTs) ||
            script.src.includes(scriptCs)) {
            script.parentNode.removeChild(script);
        }
    }
    
    const _bownow_ts = document.createElement('script');
    _bownow_ts.charset = 'utf-8';
    _bownow_ts.src = scriptTs;
    document.getElementsByTagName('head')[0].appendChild(_bownow_ts);

    const _bownow_cs = document.createElement('script');
    _bownow_cs.charset = 'utf-8';
    _bownow_cs.src = scriptCs;
    document.getElementsByTagName('head')[0].appendChild(_bownow_cs);  
};
  
export { onRouteUpdate };
